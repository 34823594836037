<template>
  <div class="FAQ">
    <div class="hearderBox">
            <span @click="backPath">
                <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span> 
            常用FAQ
        </div>
    <!-- <PublicTitle @back-call="backPath" title="常用FAQ" /> -->
    <div class="FAQContent">
      <div
        class="FAQItem"
        v-for="(item, index) in faqList"
        :key="index"
        @click="onClick(item)"
      >
        <span class="tag">
          {{ index + 1 }}
          <span class="tagTail"></span>
        </span>
        {{ item.name }}
      </div>

      <Skeleton v-if="isFirst" :skList="skList"></Skeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  components: {},
  data() {
    return {
      isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
      isFirst: true,
      faqList: [],
      skList: [
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
      ],
    };
  },
  created() {
    this.getCustomerServiceInfo();
  },
  methods: {
    async getCustomerServiceInfo() {
      let tenant = this.$cookies.get("tenant");
      const params = {
        collection: "cfg-ihelper-faq",
        filter: {
          tenantCode: tenant,
        },
      };
      let _this = this;
      this.services.GetSettings(params).then(function (res) {
        if (res.success) {
          _this.isFirst = false;
          _this.faqList = res.content.faqList;
        }
      });
    },
    onClick(item) {
      this.$store.commit("setFAQItem", item);
      this.$router.push({
        name: "FAQDetail",
      });
    },
    backPath() {
      this.iJsBridge.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.FAQ {
  // padding-top: 1rem;
  .FAQContent {
    padding: 0.26rem;

    .FAQItem {
      position: relative;
      margin-top: 0.24rem;
      padding-left: 0.8rem;
      min-height: 0.3rem;
      .tag {
        background-color: var(--themeColor);
        color: #fff;
        width: 0.3rem;
        height: 0.25rem;
        text-align: center;
        line-height: 0.25rem;
        display: inline-block;
        padding: 0 0 0 0.1rem;
        border-radius: 0.04rem;
        font-size: 0.1rem;
        padding-right: 0.15rem;
        position: absolute;
        left: 0;
        top: 0.02rem;
      }

      .tagTail {
        border: 0.125rem solid transparent;
        margin-left: -0.04rem;
        border-right: 0.1rem solid #fff;
        width: 0;
        height: 0;
        position: absolute;
        right: -0.02rem;
      }
    }
  }
}
</style>